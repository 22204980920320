//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'LPRKRBatchReportModal',
  computed: {
    duplicated: function duplicated() {
      if (this.record.duplicated.length > 10) {
        var _display_items = this.record.duplicated.slice(0, 10);

        var _display_str = _display_items.join(', ');

        _display_str = _display_str + "... (+ ".concat(this.record.duplicated.length - 10, " items)");
        return _display_str;
      }

      var display_items = this.record.duplicated;
      var display_str = display_items.join(', ');
      return display_str;
    },
    inserted: function inserted() {
      if (this.record.inserted.length > 10) {
        var _display_items2 = this.record.inserted.slice(0, 10);

        var _display_str2 = _display_items2.join(', ');

        _display_str2 = _display_str2 + "... (+ ".concat(this.record.inserted.length - 10, " items)");
        return _display_str2;
      }

      var display_items = this.record.inserted;
      var display_str = display_items.join(', ');
      return display_str;
    }
  },
  data: function data() {
    return {
      show: false,
      record: {
        duplicated: [],
        inserted: []
      }
    };
  },
  methods: {
    open: function open(param) {
      this.onClose();
      this.record = this.$utility.copy(param);
      this.show = true;
    },
    onClose: function onClose() {
      this.record = {
        duplicated: [],
        inserted: []
      };
      this.show = false;
    },
    onDownload: function onDownload() {
      this.$utility.DownloadJson(this.$utility.copy(this.record), "Batch-Report-$yyyy$mm$dd_$HH$MM$ss.json");
    }
  }
};