//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from './Group.vue';
import Plate from './Plate.vue';
export default {
  name: 'LPManagementLPRKR',
  components: {
    Group: Group,
    Plate: Plate
  },
  data: function data() {
    return {
      t: 'plate'
    };
  }
};