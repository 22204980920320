<template>
  <div v-loading="loading">
    <CCard>
      <CCardHeader>
        <strong>{{ $t('cloud.title.lpr_lp') }}</strong>
      </CCardHeader>
      <CCardBody>
        <el-form label-width="25%">
          <el-form-item :label="$t('cloud.title.lp_number')" class="mb-1">
            <el-input :placeholder="$t('cloud.title.lpr_ph_plate_number')" v-model="lp.plate" clearable style="width:240px;" :disabled="lp.id && lp.id>0" />
          </el-form-item>
          <el-form-item :label="$t('cloud.title.note')" class="mb-1">
            <el-input type="textarea" :placeholder="$t('cloud.title.lpr_lp_memo_ph')" v-model="lp.memo" :autosize="{ minRows:3, maxRows:3 }" resize="none" style="width:400px;" class="mb-2" @input="onMemo" />
          </el-form-item>
          <el-form-item :label="$t('cloud.title.group_list')" class="mb-1">
            <el-select v-model="lp.groups" :placeholder="$t('cloud.title.no_groups')" multiple style="width:320px;">
              <el-option v-for="(group, index) in groups" :label="group.name" :value="group.id" :key="`group-opts-${index}`" />
            </el-select>
          </el-form-item>
        </el-form>
      </CCardBody>
      <CCardFooter>
        <CRow class="justify-content-end mx-4">
          <CButton class="mx-1" @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
          <CButton class="mx-1" @click="onSubmit" color="primary">{{$t('button.submit')}}</CButton>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  props: {
    initData: {type: Object}
  },
  data() {
    return {
      loading: false,
      groups: [],
      lp: {
        id: null,
        plate: null,
        name: null,
        phone: null,
        memo: null,
        groups: [],
      },
      GROUP_FIELDS: [
        {key: 'id', label: this.$t('cloud.title.no')},
        {key: 'name', label: this.$t('cloud.title.group_name')},
        {key: 'memo', label: this.$t('cloud.title.note')},
        {key: 'ops', label: this.$t('cloud.title.operation'), _style: 'width:100px;text-align:center;', sorter: false}
      ]
    }
  },
  computed: {
    ...mapState([
      'capability'
    ])
  },
  mounted() {
    this.fetchInitData();
  },
  methods: {
    fetchInitData() {
      this.loading = true;
      axios.get(`/api/lpr/kr/group/?page=1&ipp=10000`)
        .then(result => {
          this.groups = this.$utility.copy(result.data.list);
        })
        .finally(() => {
          this.loading = false;
        })
      if (this.initData) {
        this.getData();
      }
    },
    getData() {
      axios.get(`/api/lpr/kr/lp/${this.initData.id}/`)
        .then(result => {
          this.lp = this.$utility.copy(result.data);
        })
        .catch(error => {
          console.error(error);
        });
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSubmit() {
      if (!this.lp.plate) {
        this.$notify.warning({
          title: this.$t('msgbox.title'),
          message: this.$t('msgbox.validate.plate'),
          offset: 30
        });
        return;
      }
      this.$confirm(this.$t('msgbox.confirm.content'), this.$t('msgbox.confirm.title'), {
        confirmButtonText: this.$t('button.ok'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(() => {
        this.$emit('payload', this.lp);
      })
    },
    onMemo() {
      const max_bytes = 250;
      let byte_num = this.$utility.checkBytes(this.lp.memo);
      while(byte_num > max_bytes) {
        this.lp.memo = this.lp.memo.substring(0, this.lp.memo.length-1);
        byte_num = this.$utility.checkBytes(this.lp.memo);
      }
    }
  }
}
</script>